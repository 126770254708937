<template>
  <div class="patE">
    <div class="banner">
      <div class="banner-box">
        <div class="banner-text">
          <p class="title animate__animated animate__fadeInLeftBig">深眸·科技企业成长性与科技力评测系统</p>
          <p class="sub-title animate__animated animate__fadeInRightBig">
            由中知数通、国家知识产权局知识产权出版社联合打造<br />通过分析挖掘企业科技情报、知识产权、人才、产业等大数据<br />评估企业的成长潜力、量化企业的科技实力，动态追踪企业赛道领域位势<br />核心应用于科技金融产品的企业市场洞察分析、科创金融业务中的目标企业与种子客户精准圈选
          </p>
          <div class="btn-container animate__animated animate__fadeInUp">
            <p class="login-btn" @click="experience('login')">{{ isLogin ? '立即使用' : '立即登录' }}</p>
            <el-popover ref="popover" placement="right" width="150" trigger="hover">
              <div id="img-container">
                <img src='https://images.zzt.com.cn/gnW/2024/04/26/wechat-code.png' style="width: 110px;">
              </div>
              <p id="code">深眸小程序</p>
            </el-popover>
            <el-button v-popover:popover class="exp-btn">
              <span>体验小程序</span>
            </el-button>
          </div>
        </div>
        <div class="banner-img" id="banner-img"></div>
      </div>
    </div>
    <section class="animate__animated animate__fadeIn">
      <div class="tec">
        <div class="tec-wrap">
          <div class="tec-content">
            <p class="title">科技力评分</p>
            <p class="desc">
              量化评测企业的科技实力和科技潜<br />
              力，把握产业位势和发展格局<br />
            </p>
            <p class="Button cursor-pointer" @click="experience('technology')">立即体验</p>
          </div>
          <img class="tec-img" src="https://images.zzt.com.cn/ks/2023/04/25/scoring-technological.png" width="810">
        </div>
      </div>
      <div class="track">
        <div class="track-wrap">
          <img class="track-img" src="https://images.zzt.com.cn/ks/2023/04/25/track-ranking.png" width="810">
          <div class="track-content">
            <p class="title">赛道领域排名</p>
            <p class="desc">精准把握概念赛道位势、实时追踪领域技术趋势、分析领域对手技术动向</p>
            <p class="Button cursor-pointer" @click="experience('track')">立即体验</p>
          </div>
        </div>
      </div>
      <div class="mark">
        <div class="mark-wrap">
          <div class="mark-content">
            <p class="title">场景定制</p>
            <p class="desc">开放企业标签、高级检索筛选项和企业详情Tab页定制，自动识别企业准入条件，助力客户高效展业</p>
            <a class="Button cursor-pointer" href="#trial">定制咨询</a>
          </div>
          <img class="mark-img" src="https://images.zzt.com.cn/gnW/2024/04/26/scene-customization.png" width="810">
        </div>
      </div>
    </section>
    <trial id="trial" title="申请深眸体验" sceneCode="2"/>
  </div>
</template>
<script>
import trial from '@/components/trial.vue'
import { mapState } from 'vuex'
import lottie from 'lottie-web'

export default {
  name: 'patE',
  components: { trial },
  created() {
    const anchor = location.hash.split('?')[0]
    if (anchor) {
      this.$nextTick(() => {
        const el = document.querySelector(anchor)
        if (el) {
          el.scrollIntoView()
        }
      })
    }
  },
  computed: {
    ...mapState(['isLogin'])
  },
  mounted() {
    this.lottie = lottie.loadAnimation({
      container: document.getElementById('banner-img'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://images.zzt.com.cn/ox/2022/12/26/data.json'
    })
  },
  methods: {
    experience(type) {
      let url = ''
      switch (type) {
        case 'login':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/search`
          break;
        case 'technology':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/ability/tecpart?id=573481777151217664`
          break;
        case 'track':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/ability/track/ipc?id=573481777151217664&ipcId=73931`
          break;
        case 'benchmarking':
          url = `${process.env.VUE_APP_JUMPURL}enterprise/ability/mark?id=573481777151217664`
          break;
      }
      if (this.$store.state.token) {
        url = `${url}${url.includes('?') ? '&' : '?'}token=${this.$store.state.token}`
        window.open(url)
      } else {
        this.$router.push(`/login/?redirect=${url}`)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.Button {
  display: inline-block;
  width: Translate(140px);
  height: Translate(36px);
  border: Translate(1px) solid #fff;
  font-size: Translate(14px);
  color: #ffffff;
  text-align: center;
  line-height: Translate(36px);
  border-radius: Translate(4px);
  background-color: #4088eb;

  &:hover {
    background: #337ee5;
    color: #fff;
    border: Translate(1px) solid #fff;
  }
}

.patE {
  p {
    margin: 0;
  }

  .banner {
    width: 100%;
    min-width: $container-width;
    height: Translate(500px);
    background: url('https://images.zzt.com.cn/ox/2022/12/23/prod-banner-bg.png') no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;

    // background-image: linear-gradient(-83deg, #68BAFF 0%, #4088EB 100%);
    &-box {
      width: $container-width;
      margin: 0 auto;
      position: relative;
      height: 100%;
    }

    &-text {
      text-align: left;
      color: #fff;

      .title {
        position: absolute;
        top: Translate(110px);
        left: 0;
        color: #333;
        font-size: Translate(40px);
        font-weight: 500;
      }

      .sub-title {
        position: absolute;
        top: Translate(180px);
        left: 0;
        color: #666;
        font-size: Translate(16px);
        line-height: Translate(34px);
      }

      .btn-container {
        width: Translate(338px);
        position: absolute;
        top: Translate(340px);
        left: 0;
        display: flex;
        justify-content: space-between;

        .login-btn {
          @include Button(160px, 40px, #4088eb, 14px, #fff);
          border: Translate(1px) solid #4088eb;

          &:hover {
            background: #337ee5;
          }
        }

        .exp-btn {
          @include Button(162px, 42px, #fff, 14px, #4088eb);
          border: Translate(1px) solid #4088eb;

          &:hover {
            background: #4088eb;
            color: #fff;
            border: 1px solid #4088eb;
          }
        }
      }
    }

    &-img {
      width: Translate(294px);
      position: absolute;
      right: Translate(50px);
      top: Translate(46px);
    }
  }

  section {
    background-color: #fff;

    img {
      font-size: 0;
    }

    .tec,
    .track,
    .mark {
      display: flex;
      align-items: center;
      justify-content: center;
      height: Translate(558px);
      min-width: $container-width;
      max-width: Translate(1920px);
      margin: 0 auto;

      &-wrap {
        display: flex;
        width: $container-width;
        justify-content: space-between;
        align-items: center;
      }

      &-content {
        width: Translate(240px);
        text-align: left;

        .title {
          font-size: Translate(32px);
          font-weight: 500;
          color: #333;
          margin-bottom: Translate(20px);
        }

        .desc {
          width: Translate(338px);
          font-size: Translate(16px);
          color: #666;
          line-height: Translate(34px);
          margin-bottom: Translate(40px);
        }
      }
    }

    .tec {
      // background: url("https://images.zzt.com.cn/ox/2022/12/12/tec-bg.png") no-repeat;
      width: 100%;
      background-position: center center;
      background-size: cover;
    }

    .track {
      // background: url("https://images.zzt.com.cn/ox/2022/12/23/track-bg.png") no-repeat;
      width: 100%;
      background-position: center center;
      background-size: cover;
      max-width: unset;
    }
  }
}

#img-container {
  width: Translate(130px);
  height: Translate(130px);
  margin-left: Translate(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#code {
  text-align: center;
  font-size: Translate(12px);
  color: #333333;
  font-weight: 400;
  padding-bottom: 15px;
}
</style>
